import React from "react";
import { MinimapTheme } from "./MinimapScroller";

interface Props {
    theme: MinimapTheme,
    label: string,
}

export const MinimapTitle: React.FC<Props> = ({ theme, label }) => {
    return (
        <div className={`minimap-title ${theme}-themed`}>
            <svg width="186" height="44" viewBox="0 0 186 44">
                <linearGradient id="blueGradient" gradientUnits="userSpaceOnUse" x1="35.6288" y1="66.4482" x2="189.7649" y2="-22.5423">
                    <stop offset="0" stop-color="#0250FF" />
                    <stop offset="1" stop-color="#407AFF" />
                </linearGradient>
                <linearGradient id="orangeGradient" gradientUnits="userSpaceOnUse" x1="35.6288" y1="66.4482" x2="189.7649" y2="-22.5423">
                    <stop offset="0" stop-color="#ff7200" />
                    <stop offset="1" stop-color="#ffc740" />
                </linearGradient>
                <path className="background" d="M3.6,42.9c-1.4,0-2.5-1.1-2.5-2.5v-0.2L14.1,4.6l0-0.1C15.3,2.3,17.6,1,20.1,1h162c1.4,0,2.5,1.1,2.5,2.5v0.2
                l-12.9,35.8l0,0.1c-1.2,2.1-3.5,3.5-5.9,3.5H3.6z"/>
                <path className="outline" d="M182.1,2c0.8,0,1.5,0.7,1.5,1.5L170.7,39c-1,1.8-3,2.9-5.1,2.9H92.9H3.6c-0.8,0-1.5-0.7-1.5-1.5L15,5
                C16,3.2,18,2,20.1,2h72.8H182.1 M182.1,0H92.9H20.1c-2.8,0-5.4,1.5-6.8,4l-0.1,0.1l-0.1,0.2L0.3,39.8l-0.1,0.3v0.4
                c0,1.9,1.5,3.5,3.5,3.5h89.2h72.8c2.8,0,5.4-1.5,6.8-4l0.1-0.1l0.1-0.2l12.8-35.5l0.1-0.3V3.5C185.6,1.6,184,0,182.1,0L182.1,0z"
                />
            </svg>
            <div className="label">
                {label}
            </div>
        </div>
    )
}